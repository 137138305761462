<template>
  <div>
    <v-btn
      v-if="label == 'Back'"
      class="backButton"
      @click="navigate(routeName)"
    ></v-btn>
    <v-btn
      v-if="label == 'Next'"
      class="nextButton"
      @click="navigate(routeName)"
    ></v-btn>
    <v-btn v-if="label == 'Home'" @click="navigate(routeName)">Home</v-btn>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  props: ["label", "routeName"],
  data: () => ({
    loading: false,
  }),
  mounted() {},

  methods: {
    navigate(routeName) {
      console.log(`navigation to ${routeName}...`);
      // this.fullUpdateDIP(
      //   this.$store.state.plan,
      //   this.$store.state.ApiUri
      // );
      this.$router.push({ name: `${routeName}` });
      this.toTop();
    },
    toTop() {
      this.$vuetify.goTo(0);
      console.log("topTop activated");
    },
  },
  computed: {},
  watch: {},
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>
