<template>
  <v-row justify="center">
    <v-col><TotalActions /></v-col>
  </v-row>
</template>

<script>
import TotalActions from "@/components/TotalActions.vue";
import ActionsReview from "@/components/ActionsReview.vue";
import AttendeesDisplay from "@/components/AttendeesDisplay.vue";
import OtherActions from "@/components/OtherActions.vue";

export default {
  props: ["expandAll"],

  components: {
    TotalActions,
    ActionsReview,
    AttendeesDisplay,
    OtherActions,
  },
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },
  data: () => ({
    panels: [],
    items: 3,
    headers: [
      {
        name: "Project Info - Review the status and progress of your recorded actions",
      },
      { name: "Other actions" },
    ],
  }),
  created() {
    // this.all();
  },
  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
    navigate(panelNumber) {
      this.$router.push({
        path: "preparation/" + panelNumber + "", // pushes panelNumber to PreparationExpansionPanel
        component: this.$route.params.tab,
      });
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
