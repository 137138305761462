<template>
  <v-container>
    <v-card>
      <v-card-text>
        <h3>Primary actions</h3>
        <br />
        <v-progress-linear
          style="pointer-events: none"
          v-model="primaryActionsProgress"
          height="25"
        >
          <strong
            >{{ Math.ceil(primaryActionsProgress) }}% completion</strong
          > </v-progress-linear
        ><br />
        <v-data-table
          disable-pagination
          dense
          :headers="primaryActionsHeaders"
          :items="primaryActionsComputed"
          item-key="name"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item="row">
            <tr>
              <td>
                <v-icon large :color="getStatusColor(row.item.Status)">{{
                  getIconType(row.item.Status)
                }}</v-icon>
              </td>
              <td>
                {{ row.item.Statement }}
              </td>
              <td>
                <!-- {{ row.item.ActionedBy }} -->
                <!-- <StaffSelect
                  :display="true"
                  :model.sync="row.item.ActionedBy"
                  :dialog.sync="row.item.ActionedBy"
                /> -->

                <div v-if="row.item.ActionedBy.Name != null">
                  {{ row.item.ActionedBy.Name }}
                </div>
                <div v-else>Not assigned</div>
              </td>
              <td>
                <v-select
                  v-model="row.item.Status"
                  :items="primaryStatusItems"
                  @change="setStatus(row.item, 'primary')"
                >
                </v-select>
              </td>
              <td>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      @click="viewDetails(row.item)"
                      ><v-icon color="blue"
                        >mdi-clipboard-text-search-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Go to Action Plan</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
        <br />
        <br />
        <h3>Other actions</h3>
        <br />
        <v-progress-linear
          style="pointer-events: none"
          v-model="otherActionsProgress"
          height="25"
        >
          <strong
            >{{ Math.ceil(otherActionsProgress) }}% completion</strong
          > </v-progress-linear
        ><br />
        <v-data-table
          disable-pagination
          dense
          :headers="otherActionsHeaders"
          :items="actionsListSummaryProxy"
          item-key="name"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item="row">
            <tr>
              <td>
                <v-icon large :color="getStatusColor(row.item.status)">{{
                  getIconType(row.item.status)
                }}</v-icon>
              </td>
              <td>
                {{ row.item.action }}
                <router-link
                  :to="{
                    name: 'ResearchPreWork',
                    params: { tab: 1 },
                  }"
                  v-if="
                    row.item.action.includes('data requirements') ||
                    row.item.action.includes('relevant metadata')
                  "
                >
                  Update Data Requirements
                </router-link>
              </td>
              <td>
                <!-- <StaffSelect
                  :display="true"
                  :model.sync="row.item.assignee"
                  :dialog.sync="row.item.assignee"
                /> -->
                <div v-if="row.item.assignee.Name != null">
                  {{ row.item.assignee.Name }}
                </div>
                <div v-else>Not assigned</div>

                <!-- <v-select
                  v-model="row.item.assignee"
                  :items="personnelList()"
                  item-text="Name"
                  item-value="Name"
                  @change="updateStore(row.item)"
                >
                </v-select> -->
              </td>
              <td>
                <v-select
                  v-model="row.item.status"
                  :items="otherStatusItems"
                  @change="setStatus(row.item, 'other')"
                >
                  ></v-select
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-btn @click="debug">DEBUG</v-btn> -->
        <!-- <v-btn @click="deleteDuplicates">delete data</v-btn> -->
      </v-card-text>
    </v-card>
    <v-dialog v-model="detailsDialog" width="70%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Action details:
        </v-card-title>

        <v-card-text>
          <v-row>
            <!--
        <v-col>
          <h3>
            Challenge / Opportunity: {{ actionFocus.ChallengeOpportunity.Name }}
          </h3>
        </v-col>
        <v-col>
          <h3>Priority: {{ actionFocus.ChallengeOpportunity.Priority }}</h3>
        </v-col>
      -->
          </v-row>
          <v-row>
            <v-col>
              <h3>Statement:</h3>
              <p>{{ actionFocus.Statement }}</p>
            </v-col>
            <v-col>
              <h3>Method:</h3>
              <p>{{ actionFocus.Method }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>Status:</h3>
              <p>{{ actionFocus.Status }}</p>
            </v-col>
            <v-col>
              <h3>Action being taken:</h3>
              <p>{{ actionFocus.Action }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>Person responsible:</h3>
              <p>{{ actionFocus.ActionedBy.Name }}</p>
            </v-col>
            <!--
        <v-col>
          <p>Due Date: {{ actionFocus.DeadlineDate }}</p>
        </v-col>
      -->
          </v-row>

          <!--
      <v-row>
        <v-col>
          <p>Action updates:</p>
        </v-col>
      </v-row>
    -->
          <div
            v-for="(update, i) in actionFocus.Updates"
            v-bind:key="update.Id"
          >
            <v-row>
              <v-col>
                <p>Update {{ i + 1 }}: {{ update.Content }}</p>
              </v-col>
              <v-col>
                <p>Date added: {{ update.DateAdded }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="detailsDialog = false" color="red" text> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="valueStoryDialog" width="30%">
      <v-card>
        <v-card-text class="text-center">
          <h3>
            <br />
            <br />
            Submit your value story!
          </h3>

          <br />
          <p>Use the following Microsoft Form to add your value story</p>
          <br />
          <v-btn
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=QYvkSjcBmUWGYfxkH-d76vmwy4FeFx9CuGoAd6BWoF9UMkpDRUpTTzFNVzJJTDRNUFI3UFg5NlNDRyQlQCN0PWcu"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            >Value Stories Form</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="valueStoryDialog = false" color="red" text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from "lodash";
import ActionsReview from "@/components/ActionsReview.vue";
import StaffSelect from "./StaffSelect.vue";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",

  components: { ActionsReview, StaffSelect },

  data: () => ({
    primaryActionsHeaders: [
      { text: "", value: "", sortable: false },
      { text: "Action", value: "Statement" },
      { text: "Person Responsible", value: "ActionedBy.Name" },
      { text: "Status", value: "Status" },
      { text: "Details", value: "Details" },
    ],
    otherActionsHeaders: [
      { text: "", value: "", sortable: false },
      {
        text: "Action",
        value: "action",
      },
      { text: "Person Responsible", value: "assignee.Name" },
      { text: "Status", value: "status" },
    ],
    actionsListSummaryProxy: [],
    otherStatusItems: ["Not started", "In progress", "Blocked", "Completed"],
    primaryStatusItems: ["Blocked", "Complete", "In Progress", "Not Started"],
    detailsDialog: false,
    valueStoryDialog: false,
    dataLocationAction: null,
    metadataAction: null,
    actionFocus: {
      Id: null,
      ChallengeOpportunity: {
        Id: null,
        Name: null,
        Type: null,
        Priority: null,
      },
      Statement: null,
      Benefit: null,
      Action: null,
      Collaborate: null,
      Method: null,
      Training: null,
      Category: null,
      ActionedBy: {
        Name: null,
        Email: null,
      },
      Notes: null,
      Status: null,
      Updates: [
        {
          Id: null,
          Content: null,
          DateAdded: null,
        },
      ],
      DeadlineDate: null,
    },
    dataBody: {
      action: null,
      assignee: {
        email: null,
        name: null,
      },
      status: null,
      dataQuestion: null,
    },
  }),
  mounted() {
    if (this.$store.state.plan.DigitalPlan.OtherActionsListSummary != null) {
      this.actionsListSummaryProxy =
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary;
    }

    this.checkDataGovernanceQuestions();
  },
  computed: {
    primaryActionsProgress() {
      let count = 0;
      for (let i = 0; i < this.primaryActionsComputed.length; i++) {
        if (this.primaryActionsComputed[i].Status == "Complete") {
          count += 1;
        }
      }
      return (count / this.primaryActionsComputed.length) * 100;
    },
    otherActionsProgress() {
      let count = 0;
      for (let i = 0; i < this.actionsListSummaryProxy.length; i++) {
        if (this.actionsListSummaryProxy[i].status == "Completed") {
          count += 1;
        }
      }
      return (count / this.actionsListSummaryProxy.length) * 100;
    },
    primaryActionsComputed() {
      return this.$store.state.plan.DigitalPlan.ActionPlan;
    },
    actionsListSummary: {
      get() {
        this.actionsListSummaryProxy =
          this.$store.state.plan.DigitalPlan.actionsListSummary;
        return this.$store.state.plan.DigitalPlan.actionsListSummary;
      },
      set(val) {
        this.actionsListSummaryProxy = val;
      },
    },
    DatasetStatus: {
      get() {
        return this.$store.getters.getDatasetStatus;
      },
    },
    DatasetContact: {
      get() {
        return this.$store.getters.getDatasetContact;
      },
    },
    MetadataStatus: {
      get() {
        return this.$store.getters.getMetadataStatus;
      },
    },
    MetadataContact: {
      get() {
        return this.$store.getters.getMetadataContact;
      },
    },
  },

  methods: {
    debug() {
      console.log(
        "current data list of other actions",
        this.actionsListSummaryProxy
      );
      console.log("current metadata contact", this.MetadataContact);
      console.log("current data location contact", this.DatasetContact);
      console.log("current status of data location button", this.DatasetStatus);
      console.log("current status of metadata button", this.MetadataStatus);
    },

    viewDetails(item) {
      this.actionFocus = item;
      this.detailsDialog = true;
    },

    checkDataGovernanceQuestions() {
      const dataLocationFlag = this.actionsListSummaryProxy.some(
        (obj) => obj.dataQuestion === "DATA_LOCATION_QUESTION_TAG"
      );
      const metadataFlag = this.actionsListSummaryProxy.some(
        (obj) => obj.dataQuestion === "METADATA_QUESTION_TAG"
      );

      if (!this.DatasetStatus) {
        if (!dataLocationFlag) {
          console.log("adding data location action");
          this.addAction(
            "DATA_LOCATION_QUESTION_TAG",
            "Review data needs and assign data location in DIP data requirements",
            this.DatasetContact
          );
        } else {
          console.log("updating data location action");
          this.updateAction("DATA_LOCATION_QUESTION_TAG", this.DatasetContact);
        }
      } else if (this.DatasetStatus && dataLocationFlag) {
        console.log("removing data location action");
        this.removeAction("DATA_LOCATION_QUESTION_TAG");
      }

      if (!this.MetadataStatus) {
        if (!metadataFlag) {
          console.log("adding metadata action");
          this.addAction(
            "METADATA_QUESTION_TAG",
            "Identify metadata standards and document the relevant metadata",
            this.MetadataContact
          );
        } else {
          console.log("updating metadata action");
          this.updateAction("METADATA_QUESTION_TAG", this.MetadataContact);
        }
      } else if (this.MetadataStatus && metadataFlag) {
        console.log("removing metadata action");
        this.removeAction("METADATA_QUESTION_TAG");
      }
    },

    addAction(dataQuestion, actionText, assignee) {
      const data = _.cloneDeep(this.dataBody);
      data.action = actionText;
      data.dataQuestion = dataQuestion;
      data.assignee = assignee;
      data.status = "Not started";
      this.actionsListSummaryProxy.push(data);
    },

    updateAction(dataQuestion, assignee) {
      this.actionsListSummaryProxy.forEach((obj) => {
        if (obj.dataQuestion === dataQuestion) {
          obj.assignee = assignee;
        }
      });
    },

    removeAction(dataQuestion) {
      this.actionsListSummaryProxy = this.actionsListSummaryProxy.filter(
        (obj) => obj.dataQuestion !== dataQuestion
      );
    },

    deleteDuplicates() {
      this.actionsListSummaryProxy = [];
      this.$store.state.plan.DigitalPlan.OtherActionsListSummary =
        this.actionsListSummaryProxy;
    },

    getStatusColor(status) {
      if (status == "Complete" || status == "Completed") {
        return "green";
      }
      if (status == "In Progress" || status == "In progress") {
        return "orange";
      }
      if (status == "Blocked" || status == "Blocked") {
        return "red";
      }
      if (
        status == "Not started" ||
        status == "Not Started" ||
        status == null
      ) {
        return "blue";
      }
    },
    getIconType(status) {
      if (status == "Complete" || status == "Completed") {
        return "mdi-progress-check";
      }
      if (status == "In Progress" || status == "In progress") {
        return "mdi-progress-clock";
      }
      if (status == "Blocked" || status == "Blocked") {
        return "mdi-progress-close";
      }
      if (
        status == "Not started" ||
        status == "Not Started" ||
        status == null
      ) {
        return "mdi-progress-alert";
      }
    },
    async setStatus(item, actionType) {
      if (actionType == "other") {
        let index;
        // get index from "action" field
        for (
          var i = 0;
          i < this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length;
          i += 1
        ) {
          if (
            this.$store.state.plan.DigitalPlan.OtherActionsListSummary[i][
              "action"
            ] === item["action"]
          ) {
            index = i;
          }
        }
        index = -1;
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary[index] =
          item;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "OtherActionsListSummary"
        );
      } else {
        // get index from "Id" field
        let index;
        for (
          var i = 0;
          i < this.$store.state.plan.DigitalPlan.ActionPlan.length;
          i += 1
        ) {
          if (
            this.$store.state.plan.DigitalPlan.ActionPlan[i]["Id"] ===
            item["Id"]
          ) {
            index = i;
          }
        }
        index = -1;
        this.$store.state.plan.DigitalPlan.ActionPlan[index] = item;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "ActionPlan"
        );
      }
      // pretty sure this is only for primary actions
      // if (item["Status"] == "Complete" || item["status"] == "Completed") {
      if (item["Status"] == "Complete") {
        console.log("testing sttus to complete");
        // in here make a dialog appear and tell user to enter value story at SP site.
        this.valueStoryDialog = true;
      }
    },
    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
    async updateStore(obj) {
      let newVal = obj.Assignee;
      // if the row.item object has DataQuestion, then update corresponding store variable

      if (obj.DataQuestion == "DATA_LOCATION_QUESTION_TAG") {
        this.$store.state.plan.DigitalPlan.DatasetContact = newVal;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DatasetContact"
        );
      }
      if (obj.DataQuestion == "METADATA_QUESTION_TAG") {
        this.$store.state.plan.DigitalPlan.MetadataContact = newVal;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "MetadataContact"
        );
      }
    },
  },
};
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}
</style>
