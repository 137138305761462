<template>
  <div>
    <PrebidNavBar />
    <v-container>
      <PlanHeader :title="'Actions'" />
      <v-row>
        <v-col cols="2">
          <v-btn block v-if="expand == false" @click="all()"
            ><span> Expand All </span> <v-icon right>mdi-chevron-down </v-icon>
          </v-btn>
          <v-btn block v-if="expand == true" @click="none()">
            <span> Collapse All </span> <v-icon right>mdi-chevron-up </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="lockDIP()">
            <div v-if="$store.state.prebidPlan.PreBidPlan.Lock == true">
              <span>Unlock DIP</span>
              <v-icon right>mdi-lock-open-variant </v-icon>
            </div>
            <div v-else>
              <span> Lock DIP </span> <v-icon right>mdi-lock </v-icon>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn class="saveButton" :style="theme" @click="saveButtonOnClick()">
          </v-btn>
        </v-col>
        <v-col cols="3"> </v-col>
        <v-col cols="3">
          Last modified by: <strong>{{ lastModified }}</strong>
        </v-col>
      </v-row>

      <div v-if="showWarning">
        <v-row>
          <v-col cols="4">
            <v-alert dense border="left" color="grey darken-1" type="warning">
              You are on the UAT / Test server!
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels accordion v-model="panels" multiple>
        <v-expansion-panel v-for="(item, i) in headers" :key="i">
          <v-expansion-panel-header>
            {{ i + 1 }}. {{ item.name }}
          </v-expansion-panel-header>

          <v-expansion-panel-content
            v-if="item.name == 'Digital Opportunities'"
          >
            <DigitalOpportunities />
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="item.name == 'Planned Actions'">
            <PlannedActions />
          </v-expansion-panel-content>

          <v-expansion-panel-content
            v-if="item.name == 'Digital Value Stories'"
          >
            <ValueStories />
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="item.name == 'Ready to Go Services'">
            <ReadyToGoServices />
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="item.name == 'Contract'">
            <Contract />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="my-10"></v-divider>

      <v-row>
        <v-col cols="2">
          <v-btn dark block color="#E61E28" @click="navigate('PrebidForm')"
            >BACK</v-btn
          >
        </v-col>
        <v-col cols="8"> </v-col>
        <v-col cols="2">
          <v-btn dark block color="#E61E28" @click="navigate('PrebidReport')"
            >NEXT</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActionsExpansionPanel from "@/components/ActionsExpansionPanel.vue";
import PlanHeader from "@/components/PlanHeader.vue";
import NavigationButton from "@/components/NavigationButton.vue";
import PrebidNavBar from "@/components/PrebidNavBar.vue";
import DigitalOpportunities from "@/components/DigitalOpportunities.vue";
import PlannedActions from "@/components/PlannedActions.vue";
import ValueStories from "@/components/ValueStories.vue";
import ReadyToGoServices from "@/components/ReadyToGoServices.vue";
import Contract from "@/components/Contract.vue";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  components: {
    ActionsExpansionPanel,
    PlanHeader,
    NavigationButton,
    PrebidNavBar,
    DigitalOpportunities,
    PlannedActions,
    ValueStories,
    ReadyToGoServices,
    Contract,
  },
  data: () => ({
    expand: false,
    panels: [],
    loading: false,
    headers: [
      { name: "Digital Opportunities" },
      { name: "Planned Actions" },
      { name: "Digital Value Stories" },
      { name: "Ready to Go Services" },
      { name: "Contract" },
    ],
    theme:
      "filter: hue-rotate(155deg) sepia(0.2) contrast(160%) saturate(4) brightness(1);",
  }),
  created() {
    // This if statement checks to see if a plan is loaded upon landing on this route
    // If a plan is not loaded, redirect to Home page. else do nothing.
    // if (this.$store.state.plan.JobNumber == null) {
    //   this.$router.push({ name: "Home" });
    // }
  },
  computed: {
    lastModified() {
      return this.$store.state.lastModified;
    },
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    all() {
      this.panels = [...Array(this.headers.length).keys()].map((k, i) => i);
      this.expand = true;
    },
    // Reset the panel
    none() {
      this.panels = [];
      this.expand = false;
    },
    navigate(routeName) {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
      this.$router.push({ name: `${routeName}` });
    },
    lockDIP() {
      if (this.$store.state.prebidPlan.PreBidPlan.Lock == null) {
        this.$store.state.prebidPlan.PreBidPlan.Lock = true;
      } else {
        this.$store.state.prebidPlan.PreBidPlan.Lock =
          !this.$store.state.prebidPlan.PreBidPlan.Lock;
      }
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
    },

    saveButtonOnClick() {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
    },
  },
};
</script>

<style src="../styles/prebidnavbar.scss" lang="scss"></style>


