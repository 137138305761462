<template>
  <v-container>
    <div v-if="actionsListSummaryProxy.length > 0">
      <v-row>
        <v-col>
          <h3>Action:</h3>
        </v-col>

        <v-col>
          <h3>Person Responsible:</h3>
        </v-col>
        <v-col>
          <h3>Status:</h3>
        </v-col>
      </v-row>
      <v-row
        v-for="action in actionsListSummaryProxy"
        v-bind:key="action.action"
      >
        <v-col>
          {{ action.action }}
        </v-col>
        <v-col>
          {{ action.assignee.Name }}
        </v-col>
        <v-col>
          <!-- {{action.assignee.Name}} -->
          <v-select
            v-model="action.status"
            :items="statusItems"
            label="Status"
            @change="
              updateStore(
                actionsListSummaryProxy,
                'OtherActionsListSummary',
                false
              )
            "
          >
            ></v-select
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>No actions set...</div>
  </v-container>
</template>
<script>
import _ from "lodash";
export default {
  name: "App",

  components: {},

  data: () => ({
    actionsListSummaryProxy: [],
    statusItems: ["Not started", "In progress", "Blocked", "Completed"],
  }),
  mounted() {
    if (this.$store.state.plan.DigitalPlan.OtherActionsListSummary != null) {
      this.actionsListSummaryProxy =
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary;
    }
  },
  computed: {
    actionsListSummary: {
      get() {
        this.actionsListSummaryProxy =
          this.$store.state.plan.DigitalPlan.actionsListSummary;
        return this.$store.state.plan.DigitalPlan.actionsListSummary;
      },
      set(val) {
        this.actionsListSummaryProxy = val;
      },
    },
  },

  methods: {
    updateStore(newVal, property, actionListFlag) {
      this.$store.state.plan.DigitalPlan[property] = newVal;
    },
  },
};
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}
</style>
